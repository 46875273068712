import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import {GeneralModalConfigType, GeneralModalResult} from "./general-modal-config-type";

@Component({
  selector: 'calingo-fnol-general-modal',
  templateUrl: './general-modal.component.html',
  styleUrls: ['./general-modal.component.scss']
})
export class GeneralModalComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<GeneralModalComponent>, @Inject(MAT_DIALOG_DATA) public data: GeneralModalConfigType) {
  }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close(GeneralModalResult.CONFIRM);
  }

  cancel() {
    this.dialogRef.close(GeneralModalResult.CANCEL);
  }

  customAction() {
    this.dialogRef.close(GeneralModalResult.CUSTOM);
  }
}
