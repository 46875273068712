import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { FnolModule } from './fnol/fnol.module'
import { CustomerDetailsComponent } from './fnol/components/customer-details/customer-details.component';
import { PetDetailsComponent } from './fnol/components/pet-details/pet-details.component';
import { SummaryComponent } from './fnol/components/summary/summary.component';
import { FormFilledGuard } from './fnol/guards/form-filled.guard';
import { FnolWrapperComponent } from './fnol/fnol-wrapper.component';

const routes: Routes = [
  { path: 'fnol', component: FnolWrapperComponent, children: [
      { path: '', component: CustomerDetailsComponent },
      { path: 'pet-details', component: PetDetailsComponent, canActivate: [FormFilledGuard] },
      { path: 'summary', component: SummaryComponent, canActivate: [FormFilledGuard] },
    ]
  },
  { path: '**', redirectTo: 'fnol' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  // imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
