// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiBaseUrl: 'https://ufr23d1n62.execute-api.eu-central-1.amazonaws.com/dev',
  translationFilesURLPrefix: 'https://pet-claims-testing.calingo.ch/assets/i18n/',
  // translationFilesURLPrefix: 'http://localhost:4200/assets/i18n/',

  companies: [
    {
      code: '06408a9c-e5aa-4a71-931b-85427342f407',
      name: 'Dev',
      cssPath: 'dev-custom.css',
      isRemote: false,
      data: {
        phoneNumber: '+48 22 307 00 00',
        // successPageUrl: 'https://pet-calingo.webflow.io/thank-you'
      }
    },
    {
      code: '0ad67977-5b02-49d2-87d2-6b6482edefdf',
      name: 'SWICA',
      cssPath: 'swica.css',
      isRemote: false,
      data: {
        phoneNumber: '0800 80 90 80',
        // successPageUrl: 'https://pet.calingo.ch/success/fnol/dog'
      }
    },
    {
      code: 'spg',
      name: 'SIMPEGO',
      cssPath: 'https://pet-claims-testing.calingo.ch/assets/css/simpego.css',
      isRemote: true,
      data: {
        phoneNumber: '+41 58 521 11 11',
        successPageUrls: {
          de: 'https://int.simpego.ch/simpego/de/service/schadenservice/tierversicherung/vielen-dank',
          en: 'https://int.simpego.ch/simpego/en/services/claims-service/pet/thanks',
          fr: 'https://int.simpego.ch/simpego/fr/service/service-des-sinistres/assurance-animaux/merci',
          it: 'https://int.simpego.ch/simpego/it/servizio/servizio-sinistri/animali-domestici/grazie'
        }
      }
    },
    {
      code: 'birchmeier',
      name: 'BIRCHMEIER',
      cssPath: 'birchmeier.css',
      isRemote: false,
      data: {
        phoneNumber: '+41 43 588 39 20',
        // successPageUrl: 'https://pet.calingo.ch/success/fnol/dog'
      }
    },
  ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
