<div class="stepper-container fnol-media-container">
	<h3 *ngIf="false" style="color: red; text-align: center; background-color: white; padding: 10px; border: 3px solid red;">{{'stepper.maintenance-warning'|translate}}</h3>
	<div *ngIf="step === 1" class="title">{{'stepper.title-first-step'|translate}}</div>
	<div *ngIf="step === 2" class="title">{{'stepper.title-second-step'|translate}}</div>
	<div *ngIf="step === 3" class="title">{{'stepper.title-third-step'|translate}}</div>
	<div *ngIf="step !== 1 && step !== 2 && step !== 3" class="title">{{'stepper.title-fallback'|translate}}</div>
	<div class="stepper">
		<div class="progress-container" #progress>
			<div class="line"></div>
			<div class="line progress-line" #progressLine></div>
			<div class="progress-icon" #progressIcon></div>
		</div>
		<div class="step first-step active" (click)="moveToStep(1)">
			<div class="dot"></div>
			<div class="text">{{'stepper.first-step'|translate}}</div>
		</div>
		<div class="step second-step" (click)="moveToStep(2)">
			<div class="dot"></div>
			<div class="text">{{'stepper.second-step'|translate}}</div>
		</div>
		<div class="step third-step" (click)="moveToStep(3)">
			<div class="dot"></div>
			<div class="text">{{'stepper.third-step'|translate}}</div>
		</div>
	</div>
</div>
