import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileInfo} from "../../services/dto/dataTypes";

@Component({
  selector: 'calingo-fnol-drag-n-drop',
  templateUrl: './drag-n-drop.component.html',
  styleUrls: ['./drag-n-drop.component.scss']
})
export class DragNDropComponent implements OnInit {
  @Input() singleFileAllowed: boolean = false;
  @Input() validate: boolean = false;

  @Output() onFileChange:EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('fileUpload', {static: true}) fileUpload!: ElementRef;

  fileList: FileInfo[] = [];
  isFileDragOver = false;
  isValid = true;

  constructor() {
  }

  get files() {
    return this.fileList;
  }

  set files(files: FileInfo[]) {
    this.fileList = files;
  }

  get filesSize() {
    return this.fileList.reduce((acc, curr) => acc + (curr.size || 0), 0);
  }

  ngOnInit(): void {
  }

  resetFiles() {
    this.fileList = [];
    this.fileUpload.nativeElement.value = '';
  }

  deleteFile(file: FileInfo) {
    this.fileList = this.fileList.filter(f => f !== file);
    this.onFileChange.emit();
  }

  dragEnterHandler(event: any) {
    this.isFileDragOver = true;
    event.preventDefault();
  }

  dragLeaveHandler(event: any) {
    this.isFileDragOver = false;
    event.preventDefault();
  }

  dragOverHandler(event: any) {
    event.preventDefault();
  }

  async dropHandler($event: DragEvent) {
    $event.preventDefault();

    if ($event.dataTransfer?.items) {

      if (this.singleFileAllowed && $event.dataTransfer.items.length > 1) {
        alert('Only one file is allowed');
        return;
      }

      for (let i = 0; i < $event.dataTransfer.items.length; i++) {
        let item = $event.dataTransfer.items[i];

        if (item.kind === 'file') {
          const file = item.getAsFile();
          if (file) {
            this.fileList.push(await this.toBase64(file));
            this.onFileChange.emit();
          }
        }
      }
    } else {
      if ($event.dataTransfer?.files) {
        if (this.singleFileAllowed && $event.dataTransfer.files.length > 1) {
          alert('Only one file is allowed');
          return;
        }

        for (let i = 0; i < $event.dataTransfer?.files?.length; i++) {
          this.fileList.push(await this.toBase64($event.dataTransfer?.files[i]));
          this.onFileChange.emit();
        }
      }
    }
  }

  async fileChangeHandler(event: any) {
    if (event.target.files) {
      if (this.singleFileAllowed && event.target.files.length > 1) {
        alert('Only one file is allowed');
        return;
      }

      for (let i = 0; i < event.target.files.length; i++) {
        this.fileList.push(await this.toBase64(event.target.files[i]));
        this.onFileChange.emit();
      }
    }
  }

  toBase64 = (file: File): Promise<FileInfo> => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve({name: this.removeSpecialCharacters(file.name), data64: reader.result as string, size: file.size});
    reader.onerror = error => reject(error);
  });

  removeSpecialCharacters(filename: string): string {
    // Replace non-ASCII characters with the provided replacement (default: '_')
    return filename.replace(/[^\x00-\x7F]+/g, '-');
  }
}
