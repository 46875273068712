export interface GeneralModalConfigType {
  title: string;
  message?: string;
  confirmButtonText: string;
  cancelButtonText?: string;
  customButtonText?: string;
}

export enum GeneralModalResult {
  CONFIRM,
  CANCEL,
  CUSTOM
}
