import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {FnolDataService} from "../fnol/services/fnol-data.service";

@Component({
  selector: 'calingo-fnol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  phone = '+41 43 588 39 20';

  constructor(private translate: TranslateService, private fnolDataService: FnolDataService) {
  }

  ngOnInit(): void {
    this.fnolDataService.customCompany.subscribe(company => {
      if (company) {
        this.phone = company.data.phoneNumber;
      }
    })
  }

  languageSelectionExpanded: boolean = false

  expand(): void {
    this.languageSelectionExpanded = true
  }

  collapse(): void {
    this.languageSelectionExpanded = false
  }

  setLanguage(lang: string) {
    this.translate.use(lang);
  }

  getSelectedLanguage(): string {
    return this.translate.currentLang
  }

}
