<form class="customer-details-container fnol-media-container" [formGroup]="customerInfoForm" (ngSubmit)="next()">
	<div class="title">
		<span class="title-1">{{'customer-details.title'|translate}}</span>
	</div>

	<div class="fnol-form-field row">
		<div class="fnol-form-field">
			<label>{{'customer-details.firstname'|translate}}</label>
			<input type="text" name="f-name" formControlName="firstName" />
		</div>
		<div class="fnol-form-field">
			<label>{{'customer-details.lastname'|translate}}</label>
			<input type="text" name="l-name" formControlName="lastName" />
		</div>
	</div>

	<div class="fnol-form-field">
		<label>{{'customer-details.email'|translate}}</label>
		<input type="email" name="email" [placeholder]="'customer-details.email-placeholder' |translate"
		       formControlName="email" />
	</div>

	<div class="fnol-form-field">
		<label>{{'customer-details.phone'|translate}}</label>
		<input type="text" name="phone-number" formControlName="phoneNumber" />
	</div>

	<div class="fnol-form-field">
		<label>{{'customer-details.contact'|translate}}</label>

		<div class="radio">
			<input type="radio" id="contactPhone"
			       name="preferredChannel" value="phone" checked formControlName="preferredChannel">
			<label for="contactPhone">{{'customer-details.contact-phone'|translate}}</label>

			<input type="radio" id="contactEmail"
			       name="preferredChannel" value="email" formControlName="preferredChannel">
			<label for="contactEmail">{{'customer-details.contact-email'|translate}}</label>
		</div>
	</div>

  <div class="fnol-form-field">
    <label>{{'customer-details.iban'|translate}}</label>
    <input type="text" name="iban" formControlName="iban" />
    <mat-hint>{{'customer-details.iban-hint'|translate}}</mat-hint>
  </div>

	<div class="button-wrapper">
		<button type="submit">{{'customer-details.next'|translate}}</button>
	</div>
</form>
