import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'calingo-fnol-wrapper',
  template: `
		<calingo-fnol-stepper></calingo-fnol-stepper>
		<router-outlet></router-outlet>
  `,
  styles: [``]
})
export class FnolWrapperComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
