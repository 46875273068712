import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FnolDataService} from "../../services/fnol-data.service";
import {AbstractControl, AsyncValidatorFn, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {DragNDropComponent} from "../drag-n-drop/drag-n-drop.component";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {DiagnosisDTO, PetInfoWithFiles} from "../../services/dto/dataTypes";
import {AutocompleteComponent} from "../autocomplete/autocomplete.component";

@Component({
  selector: 'calingo-fnol-pet-details',
  templateUrl: './pet-details.component.html',
  styleUrls: ['./pet-details.component.scss']
})
export class PetDetailsComponent implements OnInit {
  @ViewChild('vetInvoice', {static: true}) vetInvoice!: DragNDropComponent;
  @ViewChild('additionalFiles', {static: true}) additionalFiles!: DragNDropComponent;
  @ViewChild('petInfoFormElement', {static: true}) petInfoFormElement!: ElementRef;
  @ViewChild(AutocompleteComponent, {static: true}) diagnosisAutocompleteComponent!: AutocompleteComponent;

  maxDate: any = new Date();
  petInfo: PetInfoWithFiles | undefined;
  petInfoForm = new FormGroup({
    petName: new FormControl('', {updateOn: 'blur', validators: [Validators.required]}),
    conditionPreviouslyTreated: new FormControl(false, [this.validateConditionPreviouslyTreated()]),
    dateConditionNoticed: new FormControl(null, [this.validateConditionNoticed()]),
    dateConditionFirstTreated: new FormControl(null, [this.validateConditionFirstTreated()]),
    thirdPartyFault: new FormControl(false, [Validators.required]),
    additionalInformation: new FormControl(null),
    diagnosisAdditionalInformation: new FormControl(null, [this.validateDiagnosisAdditionalInformation()]),
    accidentDateField: new FormControl(null, [this.validateAccidentDateField()]),
  })

  selectedDiagnosis: DiagnosisDTO[] = [];

  showFileSizeError = false;

  showFileTypeError = false

  showCancelButton = false;

  exampleInvoiceImageSrc = this.getExampleInvoiceImagePath('de') // just a default initalization

  constructor(private readonly fnolDataService: FnolDataService, private readonly router: Router, private dialog: MatDialog, private translate: TranslateService) {

  }

  getExampleInvoiceImagePath(lang: string): string {
    if (lang === 'en') {
      return 'assets/example_invoices/E_Rechnung.png'
    }
    if (lang === 'fr') {
      return 'assets/example_invoices/F_Rechnung.png'
    }
    if (lang === 'it') {
      return 'assets/example_invoices/I_Rechnung.png'
    }
    return 'assets/example_invoices/D_Rechnung.png'
  }

  updateExampleInvoiceImageSrc(lang: string) {
    this.exampleInvoiceImageSrc = this.getExampleInvoiceImagePath(lang);
  }

  ngOnInit(): void {
    this.fnolDataService.setStep(2);
    this.setPetInfoEdit();
    this.showCancelButton = !!this.fnolDataService.petInfo && !this.fnolDataService.editPetInfoFromModal;
    this.petInfo = this.fnolDataService.petInfo;
    this.updateExampleInvoiceImageSrc(this.translate.currentLang)
    this.translate.onLangChange.subscribe(lc => {
      this.updateExampleInvoiceImageSrc(lc.lang)
    })
  }

  setPetInfoEdit() {
    if (this.fnolDataService.petInfo) {
      const petInfo = this.fnolDataService.petInfo;
      this.petInfoForm.patchValue(petInfo);
      this.vetInvoice.fileList = petInfo.vetInvoiceList;
      this.selectedDiagnosis = petInfo.diagnoses;
      if (petInfo.additionalFilesList) {
        this.additionalFiles.fileList = petInfo.additionalFilesList;
      }
      this.diagnosisAutocompleteComponent.selectedValues = this.selectedDiagnosis
    }
  }

  validate() {
    this.petInfoForm.markAllAsTouched();
    this.diagnosisAutocompleteComponent.autocompleteInputCtrl.markAllAsTouched();
    this.vetInvoice.validate = true;
    this.petInfoForm.get('diagnosisAdditionalInformation')?.updateValueAndValidity();
    this.petInfoForm.get('accidentDateField')?.updateValueAndValidity();
    this.petInfoForm.get('dateConditionNoticed')?.updateValueAndValidity();
    this.petInfoForm.get('conditionPreviouslyTreated')?.updateValueAndValidity();
    this.petInfoForm.get('dateConditionFirstTreated')?.updateValueAndValidity();

    if ( this.vetInvoice.fileList.some(f => {return f.name.endsWith('.zip') || f.name.endsWith('.tar') || f.name.endsWith('.7z') || f.name.endsWith('.rar')}) ) {
      this.showFileTypeError = true;
      return false;
    }

    if (this.vetInvoice.filesSize + this.additionalFiles?.filesSize > 4.5 * 1024 * 1024) { // Max 4.5 MB due to AWS Lambda restriction
      this.showFileSizeError = true;
      return false;
    }

    if (this.petInfoForm.invalid || this.vetInvoice.fileList.length === 0 || this.diagnosisAutocompleteComponent.autocompleteInputCtrl.invalid) {
      this.scrollToFirstInvalidControl();
      return false;
    }

    this.fnolDataService.setCurrentPetInfo(this.petInfoForm.value, this.diagnosisAutocompleteComponent.selectedValues, this.vetInvoice.fileList, this.additionalFiles?.fileList);

    this.petInfo = this.fnolDataService.petInfo;

    return true;
  }

  showDiagnosisAdditionalInformation() {
    return this.diagnosisAutocompleteComponent && this.diagnosisAutocompleteComponent.autocompleteInputCtrl.valid
      && this.diagnosisAutocompleteComponent.selectedValues.length > 0
      && !!this.diagnosisAutocompleteComponent.selectedValues.find(diagnosis => this.findDiagnosisById(diagnosis.id, diagnosis.category)?.showAdditionalInformation);
  }

  showAccidentDateField() {
    return this.diagnosisAutocompleteComponent && this.diagnosisAutocompleteComponent.autocompleteInputCtrl.valid
      && this.diagnosisAutocompleteComponent.selectedValues.length > 0
      && !!this.diagnosisAutocompleteComponent.selectedValues.find(diagnosis => this.findDiagnosisById(diagnosis.id, diagnosis.category)?.category === 'ACCIDENT');
  }

  showFieldsForNotOnlyPreventionsOrAccident() {
    return this.diagnosisAutocompleteComponent && this.diagnosisAutocompleteComponent.autocompleteInputCtrl.valid
      && this.diagnosisAutocompleteComponent.selectedValues.length > 0
      && !(this.diagnosisAutocompleteComponent.selectedValues.filter(diagnosis => this.findDiagnosisById(diagnosis.id, diagnosis.category)?.category === 'PREVENTION' || this.findDiagnosisById(diagnosis.id, diagnosis.category)?.category === 'ACCIDENT').length === this.diagnosisAutocompleteComponent.selectedValues.length);
  }

  onlyAccidentDiagnosisSelected() {
    return this.diagnosisAutocompleteComponent && this.diagnosisAutocompleteComponent.autocompleteInputCtrl.valid
      && this.diagnosisAutocompleteComponent.selectedValues.length > 0
      && this.diagnosisAutocompleteComponent.selectedValues.filter(diagnosis => this.findDiagnosisById(diagnosis.id, diagnosis.category)?.category === 'ACCIDENT').length === this.diagnosisAutocompleteComponent.selectedValues.length;
  }

  validateConditionPreviouslyTreated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.showFieldsForNotOnlyPreventionsOrAccident()) {
        return control.value !== undefined && control.value !== null ? null : {required: true}
      }

      return null;
    }
  }

  validateConditionNoticed(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.showFieldsForNotOnlyPreventionsOrAccident() || this.onlyAccidentDiagnosisSelected()) {
        return null;
      }
      return control.value ? null : {required: true}
    }
  }

  validateConditionFirstTreated(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.petInfoForm?.get('conditionPreviouslyTreated')?.value !== true) {
        console.debug('validator validateConditionFirstTreated NOT active')
        console.debug("this.petInfoForm?.get('conditionPreviouslyTreated')?.value", this.petInfoForm?.get('conditionPreviouslyTreated')?.value)
        return null;
      }
      console.debug('validator validateConditionFirstTreated active')
      console.debug("this.petInfoForm?.get('conditionPreviouslyTreated')?.value", this.petInfoForm?.get('conditionPreviouslyTreated')?.value)
      return control.value ? null : {required: true}

    }
  }

  validateDiagnosisAdditionalInformation(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.showDiagnosisAdditionalInformation()) {
        return null;
      }

      if (!this.diagnosisAutocompleteComponent.selectedValues.find(diagnosis => this.findDiagnosisById(diagnosis.id, diagnosis.category)?.additionalInformationRequired)) {
        return null;
      }

      return control.value ? null : {required: true}
    }
  }

  validateAccidentDateField(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!this.showAccidentDateField()) {
        return null;
      }
      return control.value ? null : {required: true}
    }
  }

  findDiagnosisById(id: string, category: string) {
    return this.fnolDataService.getDiagnosisCategories().find(diagnosis => diagnosis.name === category)?.values.find(diagnosis => diagnosis.id === id);
  }

  onFileListChange() {
    this.showFileSizeError = false;
  }

  next() {
    if (this.validate()) {
      this.fnolDataService.editPetInfoFromModal = false;
      this.router.navigate(['fnol', 'summary'], {queryParamsHandling: 'merge'});
    }
  }

  scrollToFirstInvalidControl() {
    const firstInvalidControl = this.petInfoFormElement.nativeElement.querySelector(
      "form .ng-invalid"
    );

    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({block: "center", behavior: "smooth"});

      if ((firstInvalidControl as HTMLElement).focus !== undefined) {
        setTimeout(() => (firstInvalidControl as HTMLElement).focus(), 1000)
      }
    }
  }
}
