import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors} from "@angular/forms";
import {map, Observable, of, startWith} from "rxjs";
import {MatAutocomplete, MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {Diagnosis, DiagnosisCategory, DiagnosisDTO} from "../../services/dto/dataTypes";
import {FnolDataService} from "../../services/fnol-data.service";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'calingo-fnol-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent {
  @ViewChild('inputElement', {static: false})
  inputElementElementRef!: ElementRef<HTMLInputElement>;
  @ViewChild('auto', {static: false}) matAutocomplete!: MatAutocomplete;

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredValues: Observable<DiagnosisCategory[]>;
  selectedValues: DiagnosisDTO[] = [];
  allValues: DiagnosisCategory[] = [];
  autocompleteInputCtrl = new FormControl('', [], [this.validateDiagnosis()]);

  constructor(private fnoDataService: FnolDataService, private translateService: TranslateService) {
    this.translateAllDiagnosis();

    this.translateService.onLangChange.subscribe(() => {
      this.translateAllDiagnosis();

      // This is a hack to force the autocomplete to update its values
      this.autocompleteInputCtrl.setValue(this.autocompleteInputCtrl.value);
    });

    this.filteredValues = this.autocompleteInputCtrl.valueChanges.pipe(
      startWith(null),
      map((value: string | null) =>
        this._filter(value || '')
      )
    );
  }

  @Input() set values(values: DiagnosisDTO[]) {
    this.selectedValues = values;
  }

  validateDiagnosis(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      const error = this.selectedValues.length === 0 ? {required: true} : null;
      return of(error)
    }
  }

  translateAllDiagnosis() {
    this.allValues = this.fnoDataService.getDiagnosisCategories().map(
      (category) => {
        return {
          ...category,
          // @ts-ignore
          displayName: category.translations[this.translateService.currentLang] || category.displayName,
          values: category.values.map(
            (diagnosis) => {
              return {
                ...diagnosis,
                // @ts-ignore
                displayName: diagnosis.translations[this.translateService.currentLang] || diagnosis.displayName
              }
            }
          )
        }
      }
    );
  }

  remove(value: DiagnosisDTO): void {
    const index = this.selectedValues.indexOf(value);

    if (index >= 0) {
      this.selectedValues.splice(index, 1);
    }

    this.autocompleteInputCtrl.setValue(this.autocompleteInputCtrl.value);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.selectedValues.push(event.option.value);
    this.inputElementElementRef.nativeElement.value = '';
    this.autocompleteInputCtrl.setValue(null);
  }

  displayChip(value: DiagnosisDTO): string {
    return this.allValues.find((category) => category.name === value.category)?.values.find((diagnosis) => diagnosis.id === value.id)?.displayName || '';
  }

  private _filter(value: string): DiagnosisCategory[] {
    if (typeof value !== 'string') {
      return this.allValues.slice();
    }
    const filterValue = value.toLowerCase();

    return this.allValues.map(
      (category) => {
        return {
          ...category,
          values: category.values.filter((diagnosis) => this.showDiagnosisForAutocomplete(diagnosis, filterValue))
        }
      }
    ).filter((category) => category.values.length > 0);
  }

  private showDiagnosisForAutocomplete(diagnosis: Diagnosis, filterValue: string): boolean {
    return diagnosis.id === "sonstige" || diagnosis.displayName.toLowerCase().indexOf(filterValue) >= 0 && !this.selectedValues.find((selectedValue) => selectedValue.id === diagnosis.id && selectedValue.category === diagnosis.category);
  }
}
