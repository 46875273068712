import {Component, OnInit} from '@angular/core';
import {FnolDataService} from "../../services/fnol-data.service";
import {CustomerInfo, FileInfo, PetInfo, PetInfoWithFiles} from "../../services/dto/dataTypes";
import {Router} from "@angular/router";
import {MatDialog} from "@angular/material/dialog";
import {TranslateService} from "@ngx-translate/core";
import {animate, style, transition, trigger} from "@angular/animations";
import {GeneralModalComponent} from "../../../general-modal/general-modal.component";
import {GeneralModalResult} from "../../../general-modal/general-modal-config-type";
import {LoadingOverlayRef, LoadingService} from "../../services/loading.service";
import * as moment from 'moment';

@Component({
  selector: 'calingo-fnol-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({height: 0, opacity: 0}),
            animate('0.5s ease-out',
              style({height: 32, opacity: 1}))
          ]
        ),
        transition(
          ':leave',
          [
            style({height: 32, opacity: 1}),
            animate('0.5s ease-in',
              style({height: 0, opacity: 0}))
          ]
        )
      ]
    )
  ]
})
export class SummaryComponent implements OnInit {
  _customerInfo: CustomerInfo | undefined;

  petInfo: PetInfoWithFiles | undefined;

  clickedSend = false

  successPageUrl: string = 'https://pet.calingo.ch/success/fnol/dog';

  constructor(private readonly fnolDataService: FnolDataService, private readonly router: Router, private dialog: MatDialog, private translate: TranslateService, private loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.fnolDataService.setStep(3);
    this._customerInfo = this.fnolDataService.customerInfo;
    this.petInfo = this.fnolDataService.petInfo;
    this.fnolDataService.customCompany.subscribe((company) => {
      if (company && company.data?.successPageUrls) {
        let currentLang = this.translate.currentLang
        if (currentLang === 'en') {
          this.successPageUrl = company.data.successPageUrls.en;
        } else if (currentLang === 'fr') {
          this.successPageUrl = company.data.successPageUrls.fr;
        } else if (currentLang === 'it') {
          this.successPageUrl = company.data.successPageUrls.it;
        } else {
          this.successPageUrl = company.data.successPageUrls.de;
        }
      }
    });
  }

  editCustomerInfo() {
    this.router.navigate(['fnol'], {queryParamsHandling: 'merge'});
  }

  editPetInfo() {
    this.router.navigate(['fnol', 'pet-details'], {queryParamsHandling: 'merge'});
  }

  openFile(file: FileInfo) {
    let newTab = window.open("", '_blank');
    newTab?.document.write('<iframe src="' + file.data64 + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
  }

  formatMomentDate(date: moment.Moment) {
    return date.format('DD.MM.YYYY');
  }

  openConfirmationModal() {
    this.translate.get(['summary.confirmation-modal.title', 'summary.confirmation-modal.text', 'summary.confirmation-modal.submit', 'summary.confirmation-modal.add-iban', 'summary.confirmation-modal.add-files']).subscribe((res: any) => {
      this.dialog.open(GeneralModalComponent, {
        data: {
          title: res['summary.confirmation-modal.title'],
          message: res['summary.confirmation-modal.text'],
          confirmButtonText: res['summary.confirmation-modal.submit'],
          cancelButtonText: res['summary.confirmation-modal.add-files'],
          customButtonText: res['summary.confirmation-modal.add-iban'],
        },
        disableClose: true
      }).afterClosed().subscribe((result: GeneralModalResult) => {
        if (result === GeneralModalResult.CONFIRM) {
          this.send();
        } else if (result === GeneralModalResult.CUSTOM) {
          this.editCustomerInfo();
        } else if (result === GeneralModalResult.CANCEL) {
          this.fnolDataService.editPetInfoFromModal = true;
          this.editPetInfo();
        }
      })
    });
  }

  send() {
    const loadingRef: LoadingOverlayRef = this.loadingService.open();
    try {
      if (this._customerInfo && this.petInfo && !this.clickedSend) { // if the user has clicked send already, and they click again, it should not trigger another backend call
        this.clickedSend = true
        this._customerInfo.lang = this.translate.currentLang;

        const vetCode = localStorage.getItem('vetCode');

        if (vetCode) {
          this.petInfo.vetCode = vetCode;
        }

        let petInfo: PetInfoWithFiles = {...this.petInfo};

        if (petInfo.diagnosisAdditionalInformation) {
          petInfo.additionalInformation = (petInfo.additionalInformation || '') + ' \n-- DIAGNOSEN INFORMATION --\n ' + petInfo.diagnosisAdditionalInformation;
          petInfo.diagnosisAdditionalInformation = undefined;
        }

        if (petInfo.accidentDateField) {
          petInfo.additionalInformation = (petInfo.additionalInformation || '') + ' \n-- UNFALLDATUM --\n ' + petInfo.accidentDateField.format('DD.MM.YYYY');
          petInfo.accidentDateField = undefined;
        }

        this.fnolDataService.sendFnolData(this._customerInfo, petInfo).subscribe((data) => {
          let petNameForURL = this.petInfo!.petName;
          let firstNameForURL = this._customerInfo!.firstName;


          this.translate.get(['summary.new-pet-details-modal.title', 'summary.new-pet-details-modal.text', 'summary.new-pet-details-modal.yes', 'summary.new-pet-details-modal.no']).subscribe((res: any) => {
            loadingRef.close();

            this.dialog.open(GeneralModalComponent, {
              data: {
                title: res['summary.new-pet-details-modal.title'],
                message: res['summary.new-pet-details-modal.text'],
                confirmButtonText: res['summary.new-pet-details-modal.yes'],
                cancelButtonText: res['summary.new-pet-details-modal.no'],
              },
              disableClose: true
            }).afterClosed().subscribe((result: GeneralModalResult) => {
              this.clickedSend = false
              if (result === GeneralModalResult.CONFIRM) {
                this.fnolDataService.deletePetInfo();
                this.router.navigate(['fnol', 'pet-details'], {queryParamsHandling: 'merge'});
              } else {
                // localStorage.removeItem('vetCode');
                const url = new URL(this.successPageUrl);
                if (url.hostname.includes('calingo.ch')) { // We only use this personalization on our own successpage
                  url.searchParams.append('petName', petNameForURL);
                  url.searchParams.append('firstName', firstNameForURL);
                }
                window.location.href = url.toString();
              }
            })
          });
        });
      }
    } catch (e) {
      loadingRef.close();
    }
  }
}
