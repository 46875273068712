<input type="file" #fileUpload (change)="fileChangeHandler($event)" [multiple]="!singleFileAllowed">

<div class="drag-container" [class.invalid]="validate && fileList.length == 0" (click)="fileUpload.click()"
     (dragover)="dragOverHandler($event)"
     (drop)="dropHandler($event)" (dragenter)="dragEnterHandler($event)" (dragleave)="dragLeaveHandler($event)"
     *ngIf="!singleFileAllowed || singleFileAllowed && fileList.length < 1">
  {{(isFileDragOver ? 'drag-n-drop.drop-here' : 'drag-n-drop.drag-n-drop')|translate}}
</div>

<div class="file-list" *ngIf="fileList.length > 0">
  <div class="file" *ngFor="let file of fileList">
    <div class="file-icon"></div>
    <div class="file-name">{{file.name}}</div>
    <div class="file-flex-placeholder"></div>
    <div class="file-delete" (click)="deleteFile(file)"></div>
  </div>
</div>
