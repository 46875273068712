export interface CustomerInfo {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  preferredChannel: string;
  lang: string;
  iban?: string;
}

export interface PetInfo {
  petName: string;
  conditionPreviouslyTreated: boolean;
  dateConditionFirstTreated: moment.Moment | undefined;
  dateConditionLastTreated: moment.Moment | undefined;
  dateConditionNoticed: moment.Moment | undefined;
  dateOfTreatment: moment.Moment | undefined;
  treatedInSwitzerland: boolean;
  invoicedAmount: number;
  treatmentFinished: boolean;
  thirdPartyFault: boolean;
  additionalInformation: string | undefined;
  vetCode?: string;
  diagnoses: DiagnosisDTO[];
  diagnosisAdditionalInformation: string | undefined;
  accidentDateField: moment.Moment | undefined;
}

export interface PetInfoWithFiles extends PetInfo {
  vetInvoiceList: FileInfo[] | undefined;
  additionalFilesList: FileInfo[] | undefined;
}

export interface FileInfo {
  name: string;
  data64: string;
  size?: number;
}

export const DateFormat = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

export interface Diagnosis {
  id: string;
  name: string;
  category: string;
  displayName: string;
  showAdditionalInformation: boolean;
  additionalInformationRequired: boolean;
  translations: {
    en: string;
    de: string;
    fr: string;
    it: string;
  }
}

export interface DiagnosisCategory {
  name: string;
  displayName: string;
  translations: {
    en: string;
    de: string;
    fr: string;
    it: string;
  }
  values: Diagnosis[];
}

export interface DiagnosisDTO {
  id: string;
  category: string;
}

export interface CustomCssCompany {
  code: string,
  name: string,
  cssPath: string,
  isRemote: boolean,
  data: {
    phoneNumber: string,
    successPageUrls?: {
      de: string,
      en: string,
      fr: string,
      it: string,
    },
  }
}
