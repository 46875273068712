import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { FnolDataService } from "../services/fnol-data.service";

@Injectable({
  providedIn: 'root'
})
export class FormFilledGuard implements CanActivate {
  constructor(private readonly fnolDataService: FnolDataService, private readonly router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // console.warn('in guard')
    // console.warn('state.url:', state.url)
    if (state.url.startsWith('/fnol/pet-details')) {
      // console.warn('in guard in pet-details branch')
      if (this.fnolDataService.customerInfo) {
        // console.warn('in guard in pet-details branch returning true')
        return true;
      }
    } else if (state.url.startsWith('/fnol/summary')) {
      // console.warn('in guard in summary branch')
      if (this.fnolDataService.petInfo) {
        // console.warn('in guard in summary branch returning true')
        return true;
      }
    }
    // console.warn('in guard returning parseurl')
    // return this.router.parseUrl('/fnol');
    return this.router.createUrlTree(['fnol'], { queryParams: route.queryParams });
    //return false
  }

}
