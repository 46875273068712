<div class="header-container">
  <div class="logo">
  </div>

  <div class="placeholder"></div>

  <div class="phone-container">
    <a [href]="'tel:'+phone" class="phone-href">{{phone}}</a>
    <div class="phone"></div>
  </div>


  <div class="language-choice-wrapper">

    <div *ngIf="!languageSelectionExpanded">
      <div (click)="expand()" class="language">{{ this.getSelectedLanguage().toUpperCase() }} &or;</div>
    </div>
    <div *ngIf="languageSelectionExpanded">
      <div (click)="collapse()" class="language">{{ this.getSelectedLanguage().toUpperCase() }} &and;</div>
    </div>
    <div *ngIf="languageSelectionExpanded" class="language-choice">
      <div *ngIf="this.getSelectedLanguage() !== 'de'" class="language" (click)="setLanguage('de'); collapse()">{{'header.language.de'|translate}}</div>
      <div *ngIf="this.getSelectedLanguage() !== 'fr'" class="language" (click)="setLanguage('fr'); collapse()">{{'header.language.fr'|translate}}</div>
      <div *ngIf="this.getSelectedLanguage() !== 'it'" class="language" (click)="setLanguage('it'); collapse()">{{'header.language.it'|translate}}</div>
      <div *ngIf="this.getSelectedLanguage() !== 'en'" class="language" (click)="setLanguage('en'); collapse()">{{'header.language.en'|translate}}</div>
    </div>

  </div>



</div>
