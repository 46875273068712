import {
  AfterContentChecked,
  AfterViewChecked,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild
} from '@angular/core';
import { FnolDataService } from "../../services/fnol-data.service";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'calingo-fnol-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @ViewChild('progress', { static: true })
  progress!: ElementRef;

  @ViewChild('progressLine', { static: true })
  progressLine!: ElementRef;

  @ViewChild('progressIcon', { static: true })
  progressIcon!: ElementRef;

  step: 1 | 2 | 3 = 1;

  stepLinks = ["", "pet-details", "summary"];

  constructor(private readonly fnolDataService: FnolDataService, private readonly router: Router, private readonly translate: TranslateService) {
    fnolDataService.step.subscribe(step => {
      this.step = step;
      if (this.progress) {
        setTimeout(() => {
          this.moveProgress();
        }, 10);
      }
    });
  }

  ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => {
      setTimeout(() => {
        this.onResize();
      }, 10);
    });

    setTimeout(() => {
      this.onResize();
    }, 10);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.renderProgress();
    this.moveProgress();
  }

  moveToStep(step: 1 | 2 | 3) {
    return;
    this.router.navigate(['fnol', this.stepLinks[step - 1]], { queryParamsHandling: 'merge' });
  }

  renderProgress() {
    const parent = this.progress.nativeElement.parentElement;
    const dots = parent.querySelectorAll('.dot');
    const firstDot = dots[0];
    const lastDot = dots[dots.length - 1];

    const width = `${lastDot.offsetLeft - firstDot.offsetLeft - lastDot.offsetWidth / 2}px`;
    const left = `${firstDot.offsetLeft + firstDot.offsetWidth / 2}px`
    const top = `${firstDot.offsetTop + firstDot.offsetHeight / 2 - 1}px`;

    this.progress.nativeElement.style.width = width;
    this.progress.nativeElement.style.left = left;
    this.progress.nativeElement.style.top = top;
  }

  moveProgress() {
    const parent = this.progress.nativeElement.parentElement;
    const dots = parent.querySelectorAll('.dot');
    const firstDot = dots[0];
    const currentDot = dots[this.step - 1];
    const prevDotIndex = new Array(...dots).indexOf(new Array(...dots).find(dot => dot.parentElement.classList.contains('active'))) + 1;
    const animationDelayMultiplier = 1 / Math.abs(prevDotIndex - this.step);

    for (let i = 0; i < dots.length; i++) {
      const transitionDelay = Math.abs(i + 1 - prevDotIndex) * animationDelayMultiplier;

      dots[i].parentElement.classList.remove('done');
      dots[i].parentElement.classList.remove('active');
      dots[i].style.removeProperty('transition-delay');
      dots[i].style.setProperty('transition-delay', `${transitionDelay}s`);
    }

    for (let i = 0; i < this.step - 1; i++) {
      dots[i].parentElement.classList.add('done');
    }

    currentDot.parentElement.classList.add('active');

    const width = `${currentDot.offsetLeft - firstDot.offsetLeft}px`;

    this.progressLine.nativeElement.style.width = width;
    this.progressIcon.nativeElement.style.left = width;
  }
}
