<div class="summary-outer-container fnol-media-container">
  <div class="title">
		<span
      class="title-1">{{ 'summary.title'|translate:{ name: _customerInfo?.firstName } }}</span>
  </div>

  <div class="summary-container" *ngIf="_customerInfo">
    <div class="summary-container-title">
      <span class="title-2">{{ 'summary.customer-details.title'|translate }}</span>
      <!--
        <img src="/assets/edit.svg" class="edit" alt="Edit" (click)="editCustomerInfo()">
      -->
      <div class="edit" alt="Edit" (click)="editCustomerInfo()"></div>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.customer-details.firstname'|translate }}</span>
      <span class="info-row-value">{{ _customerInfo.firstName }}</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.customer-details.lastname'|translate }}</span>
      <span class="info-row-value">{{ _customerInfo.lastName }}</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.customer-details.email'|translate }}</span>
      <span class="info-row-value word-break">{{ _customerInfo.email }}</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.customer-details.phone'|translate }}</span>
      <span class="info-row-value">{{ _customerInfo.phoneNumber }}</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.customer-details.contact'|translate }}</span>
      <span class="info-row-value">
				{{ (_customerInfo.preferredChannel === 'phone' ? 'customer-details.contact-phone' : 'customer-details.contact-email') | translate }}
			</span>
    </div>
  </div>

  <div class="summary-container" *ngIf="petInfo">
    <div class="summary-container-title">
			<span class="title-2">
				{{
          'summary.pet-details.title'|translate:{
            name: petInfo.petName
          }
        }}
			</span>
      <!--
        <img src="/assets/edit.svg" class="edit" alt="Edit" (click)="editPetInfo()">
      -->
      <div class="edit" alt="Edit" (click)="editPetInfo()"></div>
    </div>

    <div class="info-row" *ngIf="petInfo.conditionPreviouslyTreated">
      <span class="info-row-title">{{ 'summary.pet-details.condition-previously-treated'|translate }}</span>
      <span class="info-row-value">
				{{ (petInfo.conditionPreviouslyTreated ? "summary.pet-details.yes" : "summary.pet-details.no") | translate }}
			</span>
    </div>

    <div class="info-row" *ngIf="petInfo.conditionPreviouslyTreated">
      <span class="info-row-title">{{ 'summary.pet-details.condition-first-treated'|translate }}</span>
      <span class="info-row-value">{{ formatMomentDate(petInfo.dateConditionFirstTreated!) }}</span>
    </div>

    <div class="info-row" *ngIf="petInfo.dateConditionNoticed">
      <span class="info-row-title">{{ 'summary.pet-details.condition-noted'|translate }}</span>
      <span class="info-row-value">{{ formatMomentDate(petInfo.dateConditionNoticed) }}</span>
    </div>

    <div class="info-row" *ngIf="petInfo.vetInvoiceList && petInfo.vetInvoiceList.length>0"
         (click)="openFile(petInfo.vetInvoiceList[0])">
      <span class="info-row-title">{{ 'summary.pet-details.vet-invoice'|translate }}</span>
      <span class="info-row-value">
        <!--
          <img *ngIf="petInfo.vetInvoiceList.length > 0" src="/assets/file_icon.svg"
             class="file-icon"
             alt="File">
        -->
				<div *ngIf="petInfo.vetInvoiceList.length > 0"
             class="file-icon"
             alt="File"></div>
			</span>
    </div>

    <div class="info-row" *ngIf="petInfo.additionalFilesList && petInfo.additionalFilesList.length > 0"
         (click)="openFile(petInfo.additionalFilesList[0])">
      <span class="info-row-title">{{ 'summary.pet-details.vet-report'|translate }}</span>
      <span
        class="info-row-value">
        <!--
          <img *ngIf="petInfo.additionalFilesList.length > 0" src="/assets/file_icon.svg"
          class="file-icon"
          alt="File">
        -->
        <div *ngIf="petInfo.additionalFilesList.length > 0"
             class="file-icon"
             alt="File"></div>
			</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.pet-details.third-party-fault'|translate }}</span>
      <span
        class="info-row-value">{{ (petInfo.thirdPartyFault ? "summary.pet-details.yes" : "summary.pet-details.no") |translate }}</span>
    </div>

    <div class="info-row">
      <span class="info-row-title">{{ 'summary.pet-details.additional-info'|translate }}</span>
      <span class="info-row-value word-break">{{ petInfo.additionalInformation }}</span>
    </div>
  </div>
</div>

<footer>
  <button [ngClass]="{'button-inactive': clickedSend}"
          (click)="openConfirmationModal()">{{ 'summary.footer.send'|translate }}
  </button>
  <span> {{ 'summary.footer.info'|translate }}</span>
</footer>
