import { Component, OnInit } from '@angular/core';
import { FnolDataService } from "../../services/fnol-data.service";
import { Router } from "@angular/router";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {LoadingService} from "../../services/loading.service";

@Component({
  selector: 'calingo-fnol-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.scss']
})
export class CustomerDetailsComponent implements OnInit {

  constructor(private readonly fnolDataService: FnolDataService, private readonly router: Router, private loadingService:LoadingService) {

  }

  ngOnInit(): void {
    this.fnolDataService.setStep(1);

    if (this.fnolDataService.customerInfo) {
      this.customerInfoForm.patchValue(this.fnolDataService.customerInfo);
    }
  }

  customerInfoForm = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    lastName: new FormControl('', [Validators.required]),
    email: new FormControl('', {
      updateOn: 'blur',
      validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,63}$")]
    }),
    phoneNumber: new FormControl('', [Validators.required]),
    iban: new FormControl(''),
    preferredChannel: new FormControl('phone', [Validators.required]),
  });

  next() {
    // console.warn('submitting customer form')

    this.customerInfoForm.markAllAsTouched();

    if (this.customerInfoForm.invalid) {
      // console.warn('customer form invalid')
      return;
    }

    this.fnolDataService.setCurrentCustomerInfo(this.customerInfoForm.value);

    this.router.navigate(['fnol', 'pet-details'], {queryParamsHandling: 'merge'});
    // console.warn('submitted customer form')

  }
}
