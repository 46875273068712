<mat-chip-list #chipList aria-label="Fruit selection">
  <mat-chip
    *ngFor="let value of selectedValues"
    [selectable]="selectable"
    [removable]="removable"
    (removed)="remove(value)">
    {{displayChip(value)}}
    <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
  </mat-chip>
  <input
    placeholder="{{'autocomplete.placeholder' | translate}}"
    #inputElement
    [formControl]="autocompleteInputCtrl"
    [matAutocomplete]="auto"
    [matChipInputFor]="chipList"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    >
</mat-chip-list>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
  <mat-optgroup *ngFor="let group of filteredValues | async" [label]="group.displayName">
    <mat-option *ngFor="let diagnosis of group.values" [value]="{id:diagnosis.id, category: diagnosis.category}">
      {{diagnosis.displayName}}
    </mat-option>
  </mat-optgroup>
</mat-autocomplete>
