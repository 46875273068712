import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import {
  CustomCssCompany,
  CustomerInfo,
  DiagnosisCategory,
  DiagnosisDTO,
  FileInfo,
  PetInfo,
  PetInfoWithFiles
} from "./dto/dataTypes";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { diagnosisCategories } from "./diagnosis-categories";

@Injectable({ providedIn: 'root' })
export class FnolDataService {
  private readonly _step = new BehaviorSubject<1 | 2 | 3>(2);
  private readonly _customCompany$ = new BehaviorSubject<CustomCssCompany | undefined>(undefined);

  editPetInfoFromModal = false;

  constructor(private readonly http: HttpClient) {
  }

  get customCompany(): Observable<CustomCssCompany | undefined> {
    return this._customCompany$.asObservable();
  }

  setCustomCompany(customCompany: CustomCssCompany | undefined) {
    this._customCompany$.next(customCompany);
  }

  private _customerInfo: CustomerInfo | undefined;

  get customerInfo() {
    return this._customerInfo;
  }

  private _petInfo: PetInfoWithFiles | undefined;

  get petInfo() {
    return this._petInfo;
  }

  get step() {
    return this._step.asObservable();
  }

  setStep(step: 1 | 2 | 3) {
    this._step.next(step);
  }

  setCurrentCustomerInfo(customerInfo: CustomerInfo) {
    this._customerInfo = customerInfo;
  }

  deletePetInfo() {
    this._petInfo = undefined;
  }

  deleteOnlyPetFiles() {
    if (!this._petInfo) {
      return;
    }

    this._petInfo = {
      ...this._petInfo,
      vetInvoiceList: undefined,
      additionalFilesList: undefined
    }
  }

  setCurrentPetInfo(petInfo: PetInfo, diagnoses: DiagnosisDTO[], vetInvoiceList: FileInfo[], additionalFilesList?: FileInfo[],) {
    this._petInfo = {
      ...petInfo,
      diagnoses,
      vetInvoiceList,
      additionalFilesList,
    }
  }

  sendFnolData(customerInfo: CustomerInfo, petInfo: PetInfoWithFiles) {
    return this.http.post(environment.apiBaseUrl + '/fnol', {
      petOwnerData: customerInfo,
      vetVisitDataList: [this.petInfoSerialize(petInfo)],
      flavor: this._customCompany$.value?.name
    });
  }

  petInfoSerialize(petInfo: PetInfoWithFiles) {
    return {
      ...petInfo,
      dateConditionFirstTreated: petInfo.dateConditionFirstTreated ? petInfo.dateConditionFirstTreated.set('hour', 12).toDate().toISOString().substring(0, 10) : undefined,
      dateConditionNoticed: petInfo.dateConditionNoticed ? petInfo.dateConditionNoticed.set('hour', 12).toDate().toISOString().substring(0, 10) : undefined,
      vetInvoiceList: petInfo.vetInvoiceList?.map((vI) => this.fileInfoSerialize(vI)),
      additionalFilesList: petInfo.additionalFilesList?.map((fI) => this.fileInfoSerialize(fI))
    }
  }

  fileInfoSerialize(fileInfo: FileInfo) {
    return {
      ...fileInfo,
      data64: fileInfo.data64.split(',')[1]
    }
  }

  getDiagnosisCategories(): DiagnosisCategory[] {
    return diagnosisCategories;
  }
}
