import {Component, Inject, OnInit, Renderer2} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {filter, startWith} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../environments/environment";
import {DOCUMENT} from "@angular/common";
import {FnolDataService} from "./fnol/services/fnol-data.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'pet-fnol-fe';
  private style?: HTMLLinkElement;

  constructor(private route: ActivatedRoute, private readonly translate: TranslateService, @Inject(DOCUMENT) private document: Document, private renderer2: Renderer2, private fnolDataService: FnolDataService) {
  }

  ngOnInit() {
    localStorage.removeItem('companyCode') // remove any whitelabelling stuff, we will load it if it's really there
    localStorage.removeItem('vetCode') // remove any vet code stuff, we will load it if it's really there

    this.translate.use(localStorage.getItem('lang') || this.translate.currentLang || 'de')

    this.route.queryParams.pipe(filter(params => !!params['vetCode'])).subscribe(params => {
      localStorage.setItem('vetCode', params['vetCode']);
    })

    if ((window as any).contextFrontendConfiguration?.lang) {
      const lang = (window as any).contextFrontendConfiguration?.lang
      if (lang) {
        this.translate.use(lang);      }
    }

    this.route.queryParams.pipe(filter(params => !!params['lang'])).subscribe(params => {
      // localStorage.setItem('lang', params['lang']);
      this.translate.use(params['lang']);
    })

    this.translate.onLangChange.subscribe(() => {
      // localStorage.setItem('lang', this.translate.currentLang);
    });

    const companyCodeLS = localStorage.getItem('companyCode');

    this.route.queryParams.pipe(startWith({'custom-css': companyCodeLS})).subscribe(params => {
      if (params.hasOwnProperty('custom-css')) {
        let companyCode = params['custom-css'];

        if (companyCode) {
          localStorage.setItem('companyCode', companyCode);
        }

        const company = environment.companies.find(company => company.code === companyCode);

        if (!company) {
          return;
        }

        this.fnolDataService.setCustomCompany(company);

        this.loadStyle(company.cssPath, company.isRemote);

      } else if ((window as any).contextFrontendConfiguration?.whitelabelstyle) {
        const companyCode = (window as any).contextFrontendConfiguration?.whitelabelstyle
        if (companyCode) {
          localStorage.setItem('companyCode', companyCode);
        }
        const company = environment.companies.find(company => company.code === companyCode);

        if (!company) {
          return;
        }

        this.fnolDataService.setCustomCompany(company);

        this.loadStyle(company.cssPath, company.isRemote);
      }
    });
  }

  loadStyle(styleUrl: string, isRemote: boolean = false) {
    const cssPath = isRemote ? styleUrl : `/assets/css/${styleUrl}`;

    this.style = this.renderer2.createElement('link') as HTMLLinkElement;

    this.renderer2.setProperty(this.style, 'rel', 'stylesheet');
    this.renderer2.setProperty(this.style, 'href', cssPath);

    this.renderer2.appendChild(this.document.head, this.style);
  }
}
